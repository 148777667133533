import { SerializedError } from '@reduxjs/toolkit';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { useGetBandFullProfileQuery } from 'api/services/dossierSearch';
import { FetchErrorData } from 'types/api.type';
import { BandTag } from 'types/tags.type';

import { useAggregatedTagsByBandList } from './useAggregatedTagsByBandList';

type UseAggregatedTagsParams = {
  bandIds: number[];
  skip?: boolean;
};

type UseAggregatedTagsResult = {
  tags?: Record<number, BandTag[]>;
  isLoading: boolean;
  error?: FetchErrorData | SerializedError;
};

export const useAggregatedTags = ({
  bandIds,
  skip,
}: UseAggregatedTagsParams): UseAggregatedTagsResult => {
  const bandResult = useGetBandFullProfileQuery(skip ? skipToken : { dossierIds: bandIds });
  const bands = bandResult.data?.results;

  const tagsResult = useAggregatedTagsByBandList({ bands });

  return {
    tags: tagsResult?.tags,
    isLoading: [bandResult, tagsResult].some(({ isLoading }) => isLoading),
    error: [bandResult, tagsResult].find(({ error }) => error)?.error,
  };
};
