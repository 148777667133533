import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

import { getOidcPostLoginRedirectUri } from '../utils/getOidcPostLoginRedirectUri';

const { origin } = window.location;
const oidcConfig: UserManagerSettings = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: getOidcPostLoginRedirectUri(),
  post_logout_redirect_uri: `${origin}${process.env.PUBLIC_URL || ''}/auth/oidc/postlogout`,
  response_type: 'code',
  scope: 'openid email roles profile',
  loadUserInfo: true,
  monitorAnonymousSession: true,
  filterProtocolClaims: true,
  revokeTokensOnSignout: true,
  userStore: new WebStorageStateStore({ store: localStorage }),
};

const oidcUserManagerInstance = new UserManager(oidcConfig);

const oidcUserStoreKey = `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`;

window.addEventListener('storage', (event) => {
  if (event.key === oidcUserStoreKey) {
    oidcUserManagerInstance.getUser();
  }
});

export { oidcUserManagerInstance, oidcUserStoreKey, getOidcPostLoginRedirectUri };
