import {
  getOidcPostLoginRedirectUri,
  oidcUserManagerInstance,
} from '../oidc/oidcUserManagerInstance';

export function httpResponseUnauthorizedInterceptor(error) {
  if (error?.response?.status === 401) {
    oidcUserManagerInstance.signinRedirect({ redirect_uri: getOidcPostLoginRedirectUri() });
  }
  return Promise.reject(error);
}
