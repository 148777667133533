import { httpRequestAuthInterceptor } from '@ornament/common';
// eslint-disable-next-line import/no-unresolved
import { RetryOptions } from '@reduxjs/toolkit/dist/query/retry';
import {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from '@reduxjs/toolkit/query/react';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { FetchErrorData } from 'types/api.type';

const TIMEOUT_BEFORE_ABORT = 60_000;

export const getConfiguredBaseQuery = (
  baseUrl: string
): BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchErrorData,
  RetryOptions & {
    skipErrorResponseCamelize?: boolean;
  }
> => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    timeout: TIMEOUT_BEFORE_ABORT,
    prepareHeaders: async (headers) => {
      const config = await httpRequestAuthInterceptor({ headers });
      if (config.headers?.Authorization && !headers.has('Authorization')) {
        headers.set('Authorization', config.headers?.Authorization);
      }
      if (headers.get('Authorization') === 'omit') headers.delete('Authorization');
      return headers;
    },
  });

  const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

  return async (args: string | FetchArgs, api: BaseQueryApi, extraOptions = {}) => {
    if (typeof args !== 'string') {
      if (
        'body' in args &&
        (!args.headers ||
          ('Content-Type' in args.headers && args.headers['Content-Type'] === 'application/json'))
      ) {
        args.body = decamelizeKeys(args.body);
      }
      if ('params' in args) {
        args.params = decamelizeKeys(args.params);
      }
    }

    const result = await baseQueryWithRetry(args, api, extraOptions);

    if (result.data) {
      result.data = camelizeKeys(result.data);
    }

    if (result.error && !extraOptions.skipErrorResponseCamelize) {
      result.error = camelizeKeys(result.error) as FetchBaseQueryError;
    }

    return result;
  };
};
