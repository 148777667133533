import { api } from 'api';
import { APP_BE_URL } from 'constants/appBeUrl';
import { PaginatedResponse, PaginationRequest } from 'types/api.type';
import {
  AddNewMambaSearchPayload,
  MambaCancelResumeMutationPayload,
  MambaNearbyRequest,
  MambaNearbyRequestFilter,
  MambaNearbyRequestScanResult,
  MambaProfileLocation,
  MambaProfileLocationsParams,
  MambaProfilePosition,
  MambaProfilePositionsParams,
  MambaSearchProfilesRequest,
  MambaSearchProfilesResponse,
  MambaProfile,
  MambaFullProfile,
  EnableMambaProfilesTrackingRequest,
  DisableMambaProfilesTrackingRequest,
  UpdateMambaNearbyPayload,
  MambaScanCoverageResponse,
  MambaScanCoverageRequest,
  ToggleMambaProfileVisibilityRequest,
  MambaSearchPositionsResponse,
  MambaSearchPositionsRequest,
  MambaProfileTrajectory,
  MambaProfileTrajectoryResults,
} from 'types/mamba.type';

const MAMBA_API_BASE_URL = `${APP_BE_URL}/mambler/api/v1`;

const transformPhotos = (profile: MambaProfile) => {
  profile.photos.forEach((photo) => {
    if (photo.link.startsWith('/')) {
      photo.link = `${APP_BE_URL}${photo.link}`;
    }
  });

  return profile;
};

const mambaApi = api.injectEndpoints({
  endpoints: (build) => ({
    addNewMambaRequest: build.mutation<MambaNearbyRequest, AddNewMambaSearchPayload>({
      query: (payload) => ({
        url: `${MAMBA_API_BASE_URL}/nearby-request`,
        method: 'post',
        body: payload,
      }),
      invalidatesTags: [{ type: 'mambaScans', id: 'all' }],
    }),

    updateMambaScanById: build.mutation<MambaNearbyRequest, UpdateMambaNearbyPayload>({
      query: ({ id, ...payload }) => ({
        url: `${MAMBA_API_BASE_URL}/nearby-request/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (_res, _req, arg) => [{ type: 'mambaScans', id: arg.id }],
    }),

    getMambaScanCoverage: build.query<MambaScanCoverageResponse, MambaScanCoverageRequest>({
      query: (payload) => ({
        url: `${MAMBA_API_BASE_URL}/nearby-coverage`,
        method: 'post',
        body: payload,
      }),
    }),

    getAllMambaRequests: build.query<
      PaginatedResponse<MambaNearbyRequest>,
      MambaNearbyRequestFilter
    >({
      query: (params) => ({
        url: `${MAMBA_API_BASE_URL}/nearby-requests`,
        params,
      }),
      providesTags: [{ type: 'mambaScans', id: 'all' }],
    }),

    getMambaRequestById: build.query<MambaNearbyRequest, number>({
      query: (requestId) => ({
        url: `${MAMBA_API_BASE_URL}/nearby-request/${requestId}`,
      }),
      providesTags: (_res, _req, requestId) => [{ type: 'mambaScans', id: requestId }],
    }),

    getMambaScanByIdResults: build.query<
      PaginatedResponse<MambaNearbyRequestScanResult>,
      { requestId: number; scanId: number; filter?: PaginationRequest }
    >({
      query: ({ requestId, scanId, filter }) => ({
        url: `${MAMBA_API_BASE_URL}/nearby-request/${requestId}/scan/${scanId}/results`,
        params: filter,
      }),
      transformResponse: (response: PaginatedResponse<MambaNearbyRequestScanResult>) => {
        response.results.forEach(({ profile }) => transformPhotos(profile));
        return response;
      },
    }),

    getMambaProfile: build.query<MambaFullProfile, number>({
      query: (mambaId) => ({
        url: `${MAMBA_API_BASE_URL}/mamba/profile/${mambaId}`,
      }),
      transformResponse: (profile: MambaFullProfile) => {
        transformPhotos(profile);
        return profile;
      },
    }),

    getMambaProfiles: build.query<
      PaginatedResponse<MambaFullProfile>,
      PaginationRequest & { mambaIds?: number[]; tracked?: boolean }
    >({
      query: ({ page, pageSize, ...payload }) => ({
        url: `${MAMBA_API_BASE_URL}/mamba/profiles`,
        method: 'POST',
        params: { page, pageSize },
        body: payload,
      }),
      providesTags: [{ type: 'mambaProfiles' }],
      transformResponse: (response: PaginatedResponse<MambaFullProfile>) => {
        response.results.forEach(transformPhotos);
        return response;
      },
    }),

    getMambaProfileLocations: build.query<
      PaginatedResponse<MambaProfileLocation>,
      MambaProfileLocationsParams
    >({
      query: ({ mambaId, ...params }) => ({
        url: `${MAMBA_API_BASE_URL}/mamba/profile/${mambaId}/locations`,
        params,
      }),
    }),

    getMambaProfilePositions: build.query<
      PaginatedResponse<MambaProfilePosition>,
      MambaProfilePositionsParams
    >({
      query: ({ mambaId, ...params }) => ({
        url: `${MAMBA_API_BASE_URL}/mamba/profile/${mambaId}/positions`,
        params,
      }),
    }),

    getMambaProfileTrajectory: build.query<MambaProfileTrajectory[], number>({
      query: (mambaId) => ({
        url: `${MAMBA_API_BASE_URL}/mamba/profile/${mambaId}/trajectory`,
      }),
      transformResponse: ({ results }: MambaProfileTrajectoryResults) => results,
    }),

    /**
     * @deprecated use useSearchMambaPositionsQuery instead
     */
    searchMambaProfiles: build.query<
      PaginatedResponse<MambaSearchProfilesResponse>,
      PaginationRequest & MambaSearchProfilesRequest
    >({
      query: ({ page, pageSize, ...payload }) => ({
        url: `${MAMBA_API_BASE_URL}/search-profiles`,
        params: { page, pageSize },
        method: 'post',
        body: payload,
        timeout: 300_000,
      }),
      transformResponse: (response: PaginatedResponse<MambaSearchProfilesResponse>) => {
        response.results?.forEach(({ profile }) => {
          transformPhotos(profile);
        });

        return response;
      },
    }),

    searchMambaPositions: build.query<
      PaginatedResponse<MambaSearchPositionsResponse>,
      PaginationRequest & MambaSearchPositionsRequest
    >({
      query: ({ page, pageSize, ...payload }) => ({
        url: `${MAMBA_API_BASE_URL}/search-positions`,
        params: { page, pageSize },
        method: 'post',
        body: payload,
        timeout: 300_000,
      }),
      transformResponse: (response: PaginatedResponse<MambaSearchPositionsResponse>) => {
        response.results?.forEach(({ profile }) => {
          transformPhotos(profile);
        });

        return response;
      },
    }),

    cancelResumeMambaRequest: build.mutation<void, MambaCancelResumeMutationPayload>({
      query: ({ requestId, action }) => ({
        url: `${MAMBA_API_BASE_URL}/nearby-request/${requestId}/action/${action}`,
        method: 'post',
      }),
      invalidatesTags: [{ type: 'mambaScans', id: 'all' }],
    }),

    enableMambaProfilesTracking: build.mutation<void, EnableMambaProfilesTrackingRequest>({
      query: ({ ...payload }) => ({
        url: `${MAMBA_API_BASE_URL}/profile-tracking/enable`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'mambaProfiles' }],
    }),

    disableMambaProfilesTracking: build.mutation<void, DisableMambaProfilesTrackingRequest>({
      query: ({ mambaIds }) => ({
        url: `${MAMBA_API_BASE_URL}/profile-tracking/disable`,
        method: 'POST',
        body: { mambaIds },
      }),
      invalidatesTags: [{ type: 'mambaProfiles' }],
    }),

    toggleMambaProfileVisibility: build.mutation<void, ToggleMambaProfileVisibilityRequest>({
      query: ({ mambaId, action }) => ({
        url: `${MAMBA_API_BASE_URL}/mamba/profile/${mambaId}/action`,
        method: 'POST',
        body: { action },
      }),
    }),
  }),
});

export const {
  useAddNewMambaRequestMutation,
  useUpdateMambaScanByIdMutation,
  useGetMambaRequestByIdQuery,
  useGetAllMambaRequestsQuery,
  useGetMambaScanByIdResultsQuery,
  useGetMambaProfileQuery,
  useLazyGetMambaProfileQuery,
  useGetMambaProfilesQuery,
  useLazyGetMambaProfilesQuery,
  useGetMambaProfileLocationsQuery,
  useGetMambaProfilePositionsQuery,
  useGetMambaProfileTrajectoryQuery,

  useSearchMambaProfilesQuery,
  useSearchMambaPositionsQuery,
  useGetMambaScanCoverageQuery,
  useCancelResumeMambaRequestMutation,
  useEnableMambaProfilesTrackingMutation,
  useDisableMambaProfilesTrackingMutation,
  useToggleMambaProfileVisibilityMutation,
} = mambaApi;
