import { api } from 'api';
import { LOGIN_PAGE_URL } from 'constants/auth';
import { ROUTES } from 'constants/routes';
import store from 'store';
import { CurrentUser } from 'types/account.type';

export const redirectToLoginPage = async () => {
  const pathname = window.location.pathname.toLowerCase();
  const loginPageUrl = LOGIN_PAGE_URL.toLowerCase();
  if (
    pathname.includes(loginPageUrl) ||
    pathname.includes('/login') ||
    pathname.includes('/auth')
  ) {
    return false;
  }

  let redirectUrl = '';
  if (pathname !== '/') {
    redirectUrl = `?redirectUrl=${window.location.pathname}${window.location.search}`;
  }
  const configResponse = await store.dispatch(
    // @ts-ignore
    api.endpoints.getTransactionToKcLoginFlags?.initiate()
  );
  const config = configResponse.data;

  // @ts-ignore
  const currentUserResponse = await store.dispatch(api.endpoints.getCurrentUser?.initiate());
  const currentUser = currentUserResponse.data as CurrentUser;

  let url = `${LOGIN_PAGE_URL}${redirectUrl}`;
  if (config?.disableCookieSessionAuth || currentUser?.authMethod === 'oauth2') {
    url = `${process.env.PUBLIC_URL || ''}${ROUTES.auth.oidc.login}${redirectUrl}`;
  }

  window.location.href = url;
  return true;
};
