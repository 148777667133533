import { FC, useEffect, useState } from 'react';

import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import { Button, Link } from '@mui/material';

import { Loading } from 'components/Loading';
import { redirectToLoginPage } from 'utils/auth';

type Error = Partial<{
  name: string;
  statusText: string;
  message: string;
  request: string;
  title: string;
  actionText: string;
}>;

type Props = {
  error?: Error;
};

const ErrorPage: FC<Props> = ({ error }) => {
  const [localError, setLocalError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.error(error);
    if (error?.name === 'ChunkLoadError') {
      setLoading(true);
      fetch(error.request as string, { method: 'HEAD', redirect: 'manual' })
        .then(async (response) => {
          if (response.status === 401 || response.type === 'opaqueredirect') {
            let isRedirected = false;
            try {
              isRedirected = await redirectToLoginPage();
              if (isRedirected) return;
            } catch {
              setLocalError(error);
            }
          }
          setLocalError(error);
        })
        .catch(() => setLocalError(error))
        .finally(() => setLoading(false));
      return;
    }
    setLocalError(error);
  }, [error]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      <div style={{ paddingTop: 150 }}>
        <h3>
          <span
            style={{
              color: 'var(--mui-palette-error-main)',
              paddingRight: '16px',
              verticalAlign: 'middle',
            }}
          >
            <ReportOutlinedIcon fontSize="large" />
          </span>
          {localError?.title ?? 'Здається, що сторінка зламалась!'}
        </h3>
        <div>
          {localError?.actionText ??
            'Спробуйте повторити дію через деякий час або зверніться до підтримки!'}
        </div>
        <div
          style={{
            color: 'var(--mui-palette-text-secondary)',
            padding: '16px 0',
            maxWidth: '600px',
          }}
        >
          {!!localError && (
            <>
              <i>{localError.statusText}</i>
              <ul>
                <li>
                  <i>{localError.message}</i>
                </li>
              </ul>
            </>
          )}
        </div>
        <Button
          component={Link}
          color="primary"
          href={`${process.env.PUBLIC_URL || ''}/`}
          variant="contained"
        >
          На головну
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
