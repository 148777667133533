import { chunk } from 'lodash';

import { api } from 'api';
import { API_BASE_URL } from 'constants/url';
import { SearchSlug } from 'enums/searches';
import { createConnectorKey } from 'modules/PhotoRecognition/utils';
import { type SearchBandsPayload } from 'modules/SearchBands/types';
import { PaginatedResponse } from 'types/api.type';
import {
  BandByProfileResult,
  DossierSearchHit,
  LinkedProfilesParamsPayload,
  SearchSyntheticBandsPayload,
  SearchSyntheticBandsResult,
} from 'types/dossierSearch.type';

const DOSSIER_SEARCH = `${API_BASE_URL}/dossier/search`;
const DOSSIER_SYNTHETIC_SEARCH = `${API_BASE_URL}/dossier/synthetic_persons/search`;

const providesTags = (_res: unknown, _err: unknown, arg: SearchBandsPayload) => {
  return [{ type: 'dossierSearch' as const, id: arg.slug }];
};

const getGetBandsByConnectorQueryParams = (payload: LinkedProfilesParamsPayload) => ({
  url: `${DOSSIER_SEARCH}/linked_profiles`,
  method: 'post',
  body: payload,
});

export const dossierSearch = api.injectEndpoints({
  endpoints: (build) => ({
    getBands: build.query<PaginatedResponse<DossierSearchHit>, SearchBandsPayload>({
      query: ({ slug, ...payload }) => {
        const isPersonal = slug === SearchSlug.personal;
        return {
          url: `${DOSSIER_SEARCH}/${isPersonal ? 'full' : slug}`,
          method: isPersonal ? 'POST' : 'GET',
          ...(isPersonal ? { body: payload } : { params: payload }),
          timeout: 180_000,
        };
      },
      providesTags: (_res: unknown, _err: unknown, arg: SearchBandsPayload) => [
        { type: 'dossierSearch', id: arg.slug },
      ],
    }),

    getBandFullProfile: build.query<PaginatedResponse<DossierSearchHit>, SearchBandsPayload>({
      query: (payload) => {
        return {
          url: `${DOSSIER_SEARCH}/full`,
          method: 'post',
          body: payload,
          timeout: 180_000,
        };
      },
      providesTags,
    }),

    getSyntheticBandProfile: build.query<SearchSyntheticBandsResult, SearchSyntheticBandsPayload>({
      query: (payload) => {
        return {
          url: DOSSIER_SYNTHETIC_SEARCH,
          method: 'post',
          body: payload,
          timeout: 180_000,
        };
      },
    }),

    getBandsByConnector: build.query<DossierSearchHit[][], LinkedProfilesParamsPayload>({
      queryFn: async (payload, _queryApi, _extraOptions, baseQuery) => {
        const results = await Promise.all(
          chunk(payload.connectors, 2000).map((partialConnectors) =>
            baseQuery(
              getGetBandsByConnectorQueryParams({ ...payload, connectors: partialConnectors })
            )
          )
        );

        const error = results.find(({ error }) => error)?.error;

        if (error) {
          return { error };
        }

        return {
          data: results.reduce<DossierSearchHit[][]>(
            (acc, partialResponse) => [
              ...acc,
              ...((partialResponse.data as BandByProfileResult)?.dossiers ?? []),
            ],
            []
          ),
        };
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: 'bandByConnector' }],
    }),

    getLinkedBands: build.query<[string, DossierSearchHit[]][], LinkedProfilesParamsPayload>({
      query: (payload) => {
        return {
          url: `${DOSSIER_SEARCH}/linked_profiles`,
          method: 'post',
          body: payload,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: [{ type: 'bandByConnector' }],
      transformResponse: (response: BandByProfileResult, _meta, payload) => {
        const result = new Map<string, DossierSearchHit[]>();
        payload.connectors.forEach((item, i) => {
          const key = createConnectorKey(item);
          if (!result.has(key)) {
            result.set(key, []);
          }
          result.get(key)?.push(...(response.dossiers?.[i] || []));
        });
        return Array.from(result.entries());
      },
    }),
  }),
});

export const {
  useGetBandsQuery,
  useGetBandFullProfileQuery,
  useLazyGetBandFullProfileQuery,
  useLazyGetBandsByConnectorQuery,
  useGetBandsByConnectorQuery,
  useLazyGetSyntheticBandProfileQuery,
  useGetLinkedBandsQuery,
} = dossierSearch;
