import { circle, difference, intersect, union } from '@turf/turf';
import { Feature, GeoJsonProperties, MultiPolygon, Point, Polygon, Position } from 'geojson';
import { LatLngTuple } from 'leaflet';

import { LngLat } from 'components/Map/types';
import { CoordinateSystem } from 'enums/locations';
import { GeocodingAddress } from 'types/geocoding.type';

export const COORDINATE_FIX_FRACTION = 6;
const MAX_AGGREGATION_ITERATIONS = 30;

const regexExpIsLatLng =
  /^-?([0-8]?\d(\.\d+)?|90(\.0+)?), *-?((1[0-7]\d|\d?\d)(\.\d+)?|180(\.0+)?)$/;

// @Example WGS84: '11.111, 22.2222' || '11.111 22.2222'
const regexExpIsWGS84 =
  /^-?([0-8]?\d(\.\d+)?|90(\.0+)?)[, ] *-?((1[0-7]\d|\d?\d)(\.\d+)?|180(\.0+)?)$/;

// @Example MGRS: 36UUA2423291610 || 36U UA 2423291610
//
// ([1-9]|[1-5]\d|60) — зона від 1 до 60.
// [C-HJ-NP-X] — літерна зона (без літер "I" та "O").
// [A-Z]{2} — дві літери, що представляють квадрат 100 км.
// \d{2,10} — числова частина, що містить від 2 до 10 цифр (X та Y координати).
const regexExpIsMGRS = /^([1-9]|[1-5]\d|60)[C-HJ-NP-X]\s*[A-Z]{2}\s*\d{1,5}\s*\d{1,5}$/;

// @Example USK2000: '5591161.911115,337340.547908' || '5591161.911115 -337340.547908' || '5591161.911115, -337340.547908'
const regexExpIsUSK2000 = /^(\d{2,7}-?\d{3,5}(\.\d+)?)[, ] ?(-?\d{2,7}-?\d{3,5}(\.\d+)?)$/;

export const checkIfValidLatLng = (str: string) => regexExpIsLatLng.test(str);

const isWGS84 = (str: string): boolean => regexExpIsWGS84.test(str.trim());

const isMGRS = (str: string): boolean => regexExpIsMGRS.test(str.trim());

const isUSK2000 = (str: string) => regexExpIsUSK2000.test(str.trim());

export const checkIsCoordsValid = (str: string = '') => {
  if (isMGRS(str)) return { isCoordsValid: true, type: CoordinateSystem.MGRS };
  if (isWGS84(str)) return { isCoordsValid: true, type: CoordinateSystem.WGS84 };
  if (isUSK2000(str)) return { isCoordsValid: true, type: CoordinateSystem.UCS2000 };
  return { isCoordsValid: false, type: CoordinateSystem.MGRS };
};

export const getBoundingPoints = (points: Position[]) => {
  const lats = points.map((point: Position) => point[0]);
  const lngs = points.map((point: Position) => point[1]);

  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  const minLng = Math.min(...lngs);
  const maxLng = Math.max(...lngs);

  const boundingPoints: [LatLngTuple, LatLngTuple] = [
    [minLat, minLng],
    [maxLat, maxLng],
  ];

  return boundingPoints;
};

export const xyCoordsToYx = (coords: [number, number]) => {
  return { lng: coords[1], lat: coords[0] };
};

export const yxCoordsToXy = (coords: [number, number]) => {
  return { lng: coords[0], lat: coords[1] };
};

export const toFixedCoords = (coordinate: number): string =>
  coordinate.toFixed(COORDINATE_FIX_FRACTION);

export const formatCoordinates = (coordinatesStr: string): string => {
  if (isMGRS(coordinatesStr)) {
    return coordinatesStr
      .trim()
      .replace(/-|\s+/g, '')
      .split(',')
      .join(', ')
      .replace(/(\d{7})(\d{7})/, '$1, $2');
  }

  if (isWGS84(coordinatesStr)) {
    return coordinatesStr
      .trim()
      .replace(/\s+/g, ' ')
      .replace(/[,\s]+/, ', ')
      .replace(/(-?\d+\.\d+),\s*(-?\d+\.\d+)/, (_match, latStr, lonStr) => {
        const latitude = parseFloat(latStr);
        const longitude = parseFloat(lonStr);

        return `${toFixedCoords(latitude)}, ${toFixedCoords(longitude)}`;
      });
  }

  if (isUSK2000(coordinatesStr)) {
    return coordinatesStr
      .trim()
      .replace(/\s+/g, ' ')
      .replace(
        /(\d{2})(\d{3,5}(\.\d+)?),? ?(-?\d{2})(\d{3,5}(\.\d+)?)/,
        (_match, p1, p2, _p3, p4, p5) => {
          const latitude = parseFloat(`${p1}${p2}`);
          const longitude = parseFloat(`${p4}${p5}`);

          return `${latitude}, ${longitude}`;
        }
      );
  }

  return coordinatesStr;
};

export const parseCoordinates = (coordinatesStr: string): [number, number] => {
  const [latitudeStr, longitudeStr] = coordinatesStr.split(',').map((coord) => coord.trim());

  const latitude = parseFloat(latitudeStr);
  const longitude = parseFloat(longitudeStr);

  if (Number.isNaN(latitude) || Number.isNaN(longitude)) {
    throw new Error(
      'Invalid coordinates format. Please provide valid numbers separated by a comma.'
    );
  }

  return [latitude, longitude];
};

export const generateLocationName = (geocoding: GeocodingAddress): string => {
  const { district, hamlet, village, town, city, admin, country } = geocoding;
  const location = district || hamlet || village || town || city;

  const adminLevels = Array.from(
    { length: 7 },
    (_, i) => admin?.[`level${10 - i}` as keyof typeof admin]
  ).filter(Boolean);

  const locationName = !adminLevels.includes(location) ? [location] : [];

  const parts = [...locationName, ...adminLevels, country].filter(Boolean);

  return parts.join(', ');
};

export const getPotGeoJSON = (center: LngLat, maxRadius: number, minRadius: number = 0) => {
  const maxCircle = circle([center.lng, center.lat], maxRadius, {
    units: 'meters',
  });

  const minCircle = circle([center.lng, center.lat], minRadius, {
    units: 'meters',
  });

  return difference(maxCircle, minCircle);
};

export const isValidCoordinate = (coordinates: number[]): boolean => {
  if (!Array.isArray(coordinates) || coordinates.length !== 2) return false;
  const [lon, lat] = coordinates;
  return lon > -180 && lon < 180 && lat > -90 && lat < 90;
};

const isPolygon = (
  feature: Feature<Point | Polygon | MultiPolygon>
): feature is Feature<Polygon | MultiPolygon> => {
  return feature.geometry.type !== 'Point';
};

export const aggregatePolygons = <PropertiesType extends GeoJsonProperties>(
  features: Feature<Point | Polygon | MultiPolygon>[],
  propertiesAggregator: (aggr: NonNullable<GeoJsonProperties>, feature: Feature) => PropertiesType
) => {
  const firstNonPoint = features.find(isPolygon);

  if (!firstNonPoint) {
    return [];
  }

  let aggregated = features.reduce<Feature<Polygon | MultiPolygon, PropertiesType>[]>(
    (aggregated, feature) => {
      if (feature === firstNonPoint || !isPolygon(feature)) {
        return aggregated;
      }

      const appendToIndex = aggregated.findIndex((aggr) => intersect(aggr, feature));

      if (appendToIndex !== -1) {
        const united = union(aggregated[appendToIndex], feature);

        if (united) {
          aggregated[appendToIndex] = {
            ...aggregated[appendToIndex],
            ...united,
            properties: propertiesAggregator(aggregated[appendToIndex].properties ?? {}, feature),
          };
        }
      } else {
        aggregated.push({
          ...feature,
          properties: propertiesAggregator({}, feature),
        });
      }

      return aggregated;
    },
    [
      {
        ...firstNonPoint,
        properties: propertiesAggregator({}, firstNonPoint),
      },
    ]
  );

  let prevAggregatedCount;

  for (let i = 0; i < MAX_AGGREGATION_ITERATIONS; i++) {
    prevAggregatedCount = aggregated.length;
    aggregated = aggregated.reduce<Feature<Polygon | MultiPolygon, PropertiesType>[]>(
      (acc, currentAggregatedPolygon) => {
        const appendToIndex = acc.findIndex((polygon) =>
          intersect(polygon, currentAggregatedPolygon)
        );

        if (appendToIndex !== -1) {
          const united = union(acc[appendToIndex], currentAggregatedPolygon);

          if (united) {
            acc[appendToIndex] = {
              ...acc[appendToIndex],
              ...united,
              properties: propertiesAggregator(
                acc[appendToIndex].properties ?? {},
                currentAggregatedPolygon
              ),
            };
          }
        } else {
          acc.push(currentAggregatedPolygon);
        }

        return acc;
      },
      []
    );

    if (prevAggregatedCount === aggregated.length) {
      break;
    }
  }

  return aggregated;
};
