import PHOTO_PLACEHOLDER_PATH from 'images/user-placeholder.png';

import { APP_BE_URL } from './appBeUrl';

export const API_BASE_URL = `${APP_BE_URL}/api/v1`;

export const MAPBOX_API = 'https://api.mapbox.com';
export const MAPBOX_GEOCODING_API = `${MAPBOX_API}/geocoding/v5/mapbox.places`;

export const DEEPSTATEMAP_API_BASE_URL = `${APP_BE_URL}/deepstate/api`;

// @Example: by coords /reverse.php?lat=55.55&lon=36.86&zoom=15&format=jsonv2
export const NOMINATIM_API_BASE_URL = `${APP_BE_URL}/nominatim/reverse.php`;

export const PHOTO_PLACEHOLDER_URL = `${APP_BE_URL}${PHOTO_PLACEHOLDER_PATH}`;

export const ORYNA_URL = process.env.REACT_APP_ORYNA_URL;

export const WIKI_BASE_URL = 'https://wiki.promin.plus';
export const OB_WIKI_BASE_URL = `${WIKI_BASE_URL}/uk/band`;
