import { FC, ReactNode, useCallback } from 'react';

import {
  Button,
  DialogContentText,
  buttonBaseClasses,
  dialogContentClasses,
  typographyClasses,
} from '@mui/material';

import BasicDialogTemplate from 'components/BasicDialogTemplate';
import { useModal } from 'contexts/ModalContext';
import { Styles } from 'types/theme.type';

const styles: Styles = {
  dialog: (theme) => ({
    [`.${typographyClasses.root}`]: { color: 'text.primary' },
    [`.${buttonBaseClasses.root}`]: { fontWeight: 600 },
    [`.${dialogContentClasses.root}`]: { paddingTop: 1 },
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        margin: 2,
      },
      [`.${dialogContentClasses.root}`]: {
        paddingLeft: 2,
        paddingRight: 2,
      },
    },
  }),
};

type Props = {
  title?: string | ReactNode;
  description?: string | ReactNode;
  submitButtonText?: string | ReactNode;
  cancelButtonText?: string | ReactNode;

  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void;
  onClose?: () => void;
};

const ConfirmDialog: FC<Props> = ({
  title = '',
  description = '',
  submitButtonText = '',
  cancelButtonText = '',

  onConfirm = () => {},
  onCancel = () => {},
  onClose,
}) => {
  const { closeModal } = useModal();
  const handleCancel = useCallback(() => {
    closeModal();
    onCancel();
  }, [closeModal]);

  const handleClose = useCallback(() => {
    closeModal();
    onClose?.();
  }, [closeModal]);

  const handleConfirm = useCallback(() => {
    closeModal();
    onConfirm();
  }, [closeModal]);

  return (
    <BasicDialogTemplate
      open
      sx={styles.dialog}
      handleClose={onClose ? handleClose : handleCancel}
      contentDividers={false}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      disableEscapeKeyDown={false}
      closeOnBackdropClick={false}
      title={title}
      actions={
        <>
          <Button onClick={handleCancel} variant="text" color="inherit">
            {cancelButtonText || 'Відмінити'}
          </Button>
          <Button onClick={handleConfirm} autoFocus variant="text" color="primary">
            {submitButtonText || 'Погодитись'}
          </Button>
        </>
      }
    >
      <DialogContentText id="confirm-dialog-description">{description}</DialogContentText>
    </BasicDialogTemplate>
  );
};

export default ConfirmDialog;
